import React, { useState } from 'react'
import '../Styles/verify.scss'
import { Link, useParams } from "react-router-dom"

import { BsCheckCircleFill } from 'react-icons/bs';
function NewCategory() {
    const [isVerifySuccess, SetIsVerifySuccess] = useState(false)

    const [note, setNote] = useState("No Notes")
    const [title, setTitle] = useState("No Notes")
    const [topCategory, setTopCategory] = useState("No Notes")
    const [postResponse, setPostResponse] = useState("")

    const addCategory = (e) => {
        e.preventDefault()
        var myHeaders = new Headers();
        myHeaders.append("userid", "1");
        myHeaders.append("hasspass", "23495505togg");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "title": title,
            "topCategory": topCategory,
            "note": note
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://skillo.justwebtech.com/api/skill/category/", requestOptions)
            .then(response => response.text())
            .then(result => SetIsVerifySuccess(true))
            .catch(error => setPostResponse('error', error));

    }


    return (
        <div className="my-3  p-3container">
            <section className="verify__container">
                {
                    isVerifySuccess == false ?

                        <form className="verify__form  p-3">

                            <input className="my-3" type="text" onInput={(e) => setTitle(e.target.value)} placeholder="Title" />
                            <br />
                            <input className="my-3" type="text" onInput={(e) => setTopCategory(e.target.value)} placeholder="Top Category" />
                            <br />
                            <textarea className="my-3" type="text" onInput={(e) => setNote(e.target.value)} placeholder="VERIFY NOTE" rows="4" cols="50" ></textarea>

                            {/* <input className="my-3" type="text" placeholder="note" /> */}
                            <br />
                            {/* <button onClick={addCategory} >Verify</button> */}
                            <div className="text-center">
                                <button onClick={addCategory} className="submit_btn px-5 py-1  my-1">Add New Category</button>

                            </div>
                            <span className="text-success" >{postResponse}</span>
                        </form>

                        :
                        <div className="verifySuccessful text-center py-5 px-5 rounded">
                            <div className="fs-1 mb-2"><BsCheckCircleFill /></div>
                            <h5>Skill Verified Successfully!</h5>

                            <Link to={`/category`} >
                                <button className="btn btn-primary mt-3">Go Back</button>
                            </Link>

                        </div>
                }
            </section>
        </div>
    )
}

export default NewCategory
