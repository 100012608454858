import React,{useEffect,useState} from 'react';
import { Link,useLocation } from 'react-router-dom'
import logo from '../Images/logo.svg'

function NavBar() {
    const location = useLocation()
    const [checkPath,setCheckPath]=useState(false)

    useEffect(() => {
if(location.pathname=="/signup" ){
    setCheckPath(true)
}
else if(location.pathname=="/login"){
    setCheckPath(true) 
}
else{
    setCheckPath(false)
}
    },[])

    return (
        <div className={`container-fluid bg-light mb-3 ${checkPath ? "fixed-top" : "sticky-top"}`} style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}>
         {/* <div className="container-fluid bg-light mb-3 sticky-top" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}> */}
            <nav class="navbar d-flex justify-content-between align-items-center container">
                <Link class="navbar-brand text-purple" to="/"> <img src={logo} width="100px"  height="50px" alt="" /> </Link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="" >
                    <ul class="navbar-items d-flex">
                        <li class="nav-item ">
                            <Link class="nav-link text-dark" to="/">Home </Link>
                        </li>
                        {/* <li class="nav-item">
                            <Link class="nav-link text-dark" to="/about">About</Link>
                        </li> */}

                        <li class="nav-item">
                            <Link class="nav-link text-dark" to="/privacy">Privacy</Link>
                        </li>
                    </ul>

                </div>
            </nav >
            

            <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Pricing</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled">Disabled</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
        </div >
        );
}

export default NavBar;
