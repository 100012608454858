import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import '../Styles/table.scss'
import requests from '../Helpers/requests'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


function AppUsers() {
    const [isLoading, setLoading] = useState(true)

    const [appUsers, setAppUsers] = useState([])

    useEffect(() => {
        axios.get(`${requests.getAppUsers}`).then((response) => {
            setAppUsers(response.data)
            setLoading(false)
        })
    }, [])
    return (
        <div className="container table-responsive my-3 position-relative" >
            {
                isLoading ?
                    <div className="loader">
                        {/* <h5>Content Loading</h5> */}
                        <Loader
                            type="ThreeDots"
                            color="#83418b"
                            height={100}
                            width={100}
                            timeout={0} //0 secs
                        />
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-center align-items-center my-3">
                            <h3 className="text-center d-inline" >APP USERS </h3> <span class="badge badge-pill">{appUsers.length}</span>
                        </div>


                        <table>
                            <thead>
                                <tr>
                                    <th scope="col"> ID</th>
                                    <th scope="col">first Name</th>
                                    <th scope="col">last Name</th>
                                    <th scope="col">email</th>
                                    <th scope="col">phone</th>
                                    <th scope="col"> created By</th>
                                    <th scope="col"> created Date</th>
                                    <th scope="col"> modified Date</th>
                                    <th scope="col"> modified By</th>
                                    <th scope="col"> status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    appUsers.map((appUser, index) => (
                                        <tr key={index}>
                                            <th scope="row">{appUser.id}</th>
                                            <td>{appUser.firstName} </td>
                                            <td>{appUser.lastName} </td>
                                            <td>{appUser.email} </td>
                                            <td>{appUser.phone} </td>
                                            <td>{appUser.createdBy} </td>
                                            <td>{appUser.createdDate} </td>
                                            <td>{appUser.modifedDate} </td>
                                            <td>{appUser.modifiedBy} </td>
                                            <td>{appUser.status} </td>

                                            {/* <td><div className="table__button rounded-circle" ><Link to={`/member/${appUser.id}`}  className=" ">  <FaInfoCircle /></Link> </div></td> */}

                                        </tr>

                                    ))
                                }

                            </tbody>
                        </table>
                    </>
            }

        </div>
    )
}

export default AppUsers
