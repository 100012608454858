import React,{useState,useEffect} from 'react'
import axios from 'axios';
import {Link} from 'react-router-dom'
import '../Styles/table.scss'
import requests from '../Helpers/requests'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function Payments() {
    const [isLoading, setLoading] = useState(true)

    const [payments,setPayments] = useState([]) 
    
    useEffect(()=>{
        axios.get(`${requests.getPayments}`).then((response)=>{
            setPayments(response.data)
            setLoading(false)
        })
    },[])
        return (
            <div className="container table-responsive my-3 position-relative" >
            {
            isLoading ?   
            <div className="loader">
                 {/* <h5>Content Loading</h5> */}
            <Loader
            type="ThreeDots"
            color="#83418b"
            height={100}
            width={100}
            timeout={0} //0 secs
            />
            </div>
            :
            <>
                           <div className="d-flex justify-content-center align-items-center my-3">
                           <h3 className="text-center d-inline" >PAYMENTS </h3> <span class="badge badge-pill">{payments.length}</span>
                           </div>
    
            
                        <table>
                            <thead>
                                <tr>
                                <th scope="col">ID</th>
                        <th scope="col">amount </th>
                        <th scope="col">NOTE</th>
                        <th scope="col">Create Date</th>
                        <th scope="col">ref id</th>
                        <th scope="col">sourceType</th>
                        <th scope="col">debit / Credit</th>
                                </tr>
                            </thead>
                            <tbody>
                     
                                {
                                    payments.map((payment,index) =>(
                                        <tr key={index}>
                                       <th scope="row">{payment.id}  </th>
                        <td scope="row">#{new Intl.NumberFormat().format(payment.amount)}  </td>
                    <td scope="row">{payment.note}  </td>
                    <td scope="row">{payment.createDate}  </td>
                    <td scope="row">{payment.refId}  </td>
                    <td scope="row">{payment.sourceType}  </td>
                    <td scope="row">{payment.debitCredit=="C" ? "Credit" : "Debit" } </td>

            
                                        {/* <td><div className="table__button rounded-circle" ><Link to={`/member/${appUser.id}`}  className=" ">  <FaInfoCircle /></Link> </div></td> */}
            
                                        </tr>
                    
                                    ))
                                }
                               
                            </tbody>
                        </table>
                        </>
                 }
            
                    </div>
        )
    }

export default Payments
