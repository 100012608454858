import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import '../Styles/home.scss'
import requests from '../Helpers/requests'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
function Home() {
  const [isLoading, setLoading] = useState(true)
  const [cat, setCat] = useState(0)

  const getCateg = () => {
    axios.get(`${requests.getCategory}`).then((response) => setCat(response.data.length))
  }

  const getMembers = axios.get(`${requests.getMembers}`);
  const getSkills = axios.get(`${requests.getSkills}`);
  const getJobs = axios.get(`${requests.getJobs}`);
  const getAbuses = axios.get(`${requests.getAbuses}`);
  const getPayments = axios.get(`${requests.getPayments}`);
  const getAppUsers = axios.get(`${requests.getAppUsers}`);
  const getReferences = axios.get(`${requests.getReferences}`);
  const getApplyJobs = axios.get(`${requests.getApplyJobs}`);
  const getPenalties = axios.get(`${requests.getPenalties}`);

  const [members, setMembers] = useState([])
  const [skills, setSkills] = useState([])
  const [jobs, setJobs] = useState([])
  const [abuses, setAbuses] = useState([])
  const [appUsers, setAppUsers] = useState([])
  const [payments, setPayments] = useState([])
  const [references, setReferences] = useState([])
  const [applyJobs, setApplyJobs] = useState([])
  const [penalties, setPenalties] = useState([])
  const [category, setCategory] = useState([])

  useEffect(() => {
    axios
      .all([getMembers, getSkills, getJobs, getAbuses, getPayments, getAppUsers, getReferences, getApplyJobs, getPenalties])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];
          const responseThree = responses[2];
          const responseFour = responses[3];
          const responseFive = responses[4];
          const responseSix = responses[5];
          const responseSeven = responses[6];
          const responseEight = responses[7];
          const responseNine = responses[8];

          // use/access the results
          setMembers(responseOne.data.length)
          setSkills(responseTwo.data.length)
          setJobs(responseThree.data.length)
          setAbuses(responseFour.data.length)
          setPayments(responseFour.data.length)
          setAppUsers(responseFive.data.length)
          setReferences(responseSix.data.length)
          setApplyJobs(responseSeven.data.length)
          setPenalties(responseEight.data.length)
          setLoading(false); //stop loading when data is fetched
          getCateg()
        })
      )
      .catch(errors => {
        // react on errors.
        console.error(errors);
      });
  }, [])

  return (
    <div className="container  " >

      {
        isLoading ?
          <div className="loader">

            <Loader
              type="ThreeDots"
              color="#83418b"
              height={100}
              width={100}
              timeout={0} //0 secs
            />
          </div>

          :

          <div className="homepage container" >

            <div className="col-12  p-3">


              <h3 className="text-center mt-3 pt-3">WELCOME TO VERIFIED SKILLS</h3>
            </div>

            <section className="row my-5">

              <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-2 pt-3  home__cards home__cards">
                <section >
                  <div className="text-center">
                    <h2>{members}</h2>
                    <p>Members</p>
                  </div>
                </section>
              </div>

              <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-2 pt-3  home__cards home__cards">
                <section >
                  <div className="text-center">
                    <h2>{skills}</h2>
                    <p>Skills</p>
                  </div>
                </section>
              </div>

              <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-2 pt-3  home__cards home__cards">
                <section >
                  <div className="text-center">
                    <h2>{jobs}</h2>
                    <p>Jobs</p>
                  </div>
                </section>
              </div>


              <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-2 pt-3  home__cards home__cards">
                <section >
                  <div className="text-center">
                    <h2>{cat}</h2>
                    <p>Categories</p>
                  </div>
                </section>
              </div>

              <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-2 pt-3  home__cards home__cards">
                <section >
                  <div className="text-center">
                    <h2>{payments}</h2>
                    <p>Payments</p>
                  </div>
                </section>
              </div>

              <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-2 pt-3  home__cards home__cards">
                <section >
                  <div className="text-center">
                    <h2>{applyJobs && 0}</h2>
                    <p>Apply Jobs</p>
                  </div>
                </section>
              </div>

              <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-2 pt-3  home__cards home__cards">
                <section >
                  <div className="text-center">
                    <h2>{appUsers}</h2>
                    <p>App Users</p>
                  </div>
                </section>
              </div>


              <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-2 pt-3  home__cards home__cards">
                <section >
                  <div className="text-center">
                    <h2>{references}</h2>
                    <p>References</p>
                  </div>
                </section>
              </div>


              <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-2 pt-3  home__cards home__cards">
                <section >
                  <div className="text-center">
                    <h2>{abuses}</h2>
                    <p>Abuses</p>
                  </div>
                </section>
              </div>





              <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-2 pt-3  home__cards home__cards">
                <section >
                  <div className="text-center">
                    <h2>{penalties && 0}</h2>
                    <p>Penalties</p>
                  </div>
                </section>
              </div>




            </section>

          </div>

      }
    </div >
  )
}

export default Home
