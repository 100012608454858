import React from 'react'
import TableHeadings from './TableHeadings'

function MemberJobs({jobs}) {
    return (
        <div className="col-12 ">

                  <div className="table-responsive " >
{

jobs.length > 0 ?

<div className="overflow__scroll">
     <table className="table ">

<TableHeadings  headings={[
                    "id",
                    "TITLE",
                    "note",
                    "create date",
                    "Expiry Date",
                    "requirements"
                    ]} />
                    <tbody>

                    {
                        jobs.map((job,index) =>(
                            <tr key={index}>
                            <th scope="row">{job.id}  </th>
                            <td scope="row">{job.title}  </td>
<td scope="row">{job.note}  </td>
<td scope="row">{job.createDate.slice(0,10)}  </td>
<td scope="row">{(job.expiredDate===true)?<span className="badge bg-danger">Expired</span> : job.expiredDate.slice(0,10)}  </td>
<td scope="row">{job.requirements}  </td>
                           </tr>
                        ))

                    }



</tbody>
                    
                      </table>
</div>
                     

:<h5 className="text-danger"><u>NO DATA TO DISPLAY</u></h5>

}
                  </div>
              </div>
    )
}

export default MemberJobs
