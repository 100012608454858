import React from 'react'
import TableHeadings from './TableHeadings'

function MemberRequests({requests}) {
    return (
        <div className="col-12 ">

        <div className=" overflow__scroll" >
{
requests.length > 0 ?

            <table className="table ">

                <TableHeadings  headings={[
                    "id",
                    "amount agreed",
                    "amount Paid",
                    "Payment Status",
                    "Seal",
                    "Work Done",
                    "note",
                    "rating",
                    "create date",
                    "phone",
                    "address",
                    "payment Date",
                    "seal Date",
                    "work done Date",
                    ]} />

          <tbody>

          {
            requests.map((request,index) =>(
              <tr key={index}>
              <th >{request.id}  </th>
          <td scope="row">#{new Intl.NumberFormat().format(request.amountAgreed )}  </td>
          <td scope="row">#{new Intl.NumberFormat().format(request.amountPaid)}  </td>
          <td >{request.isPayment ?<span className="text-success" >Paid!</span> :<span className="text-danger" >Unpaid</span>  }  </td>
          <td >{request.isSeal ?<span className="text-success" >Sealed!</span> :<span className="text-danger" >UnSealed</span>  }  </td>
          <td >{request.isWorkDone ?<span className="text-success" >Completed!</span> :<span className="text-danger" >UnCompleted</span>  }  </td>
          <td >{request.note}  </td>
          <td >{request.rate}  </td>
          <td >{request.createDate}  </td>
          <td >{request.phone}  </td>
          <td >{request.address}  </td>
          <td >{request.paymentDate}  </td>
          <td >{request.sealDate}  </td>
          <td >{request.workDoneDate}  </td>
                 </tr>
                 // {console.log(skill.note)}
              ))

          }


</tbody>
          
            </table>

:<h5 className="text-danger"><u>NO DATA TO DISPLAY</u></h5>
}
        </div>
    </div>
    )
}

export default MemberRequests
