import React,{useEffect,useState} from 'react'
import { FaGem, FaHeart, FaBars, FaXingSquare } from 'react-icons/fa';
import { HiMenuAlt3, HiMenuAlt2 } from 'react-icons/hi';
import { Link, useLocation } from "react-router-dom"

import '../Styles/header.scss'
function Header({ ToggleSidebar, showSidebar }) {
  const location = useLocation()
 
  const [checkPath,setCheckPath]=useState(false)

  useEffect(() => {
if(location.pathname=="/privacy" ){
  setCheckPath(true)
}
else if(location.pathname=="/"){
  setCheckPath(true) 
}
else{
  setCheckPath(false)
}
  },[])

  return (
    <>
    {/* {
      checkPath  ? ""
  : */}
    <header className="sticky-top align-items-center w-100 px-5 d-flex">
      <nav className=" d-flex align-items-center justify-content-between py-1 ">

        <div className=" d-flex container align-items-center">
          <div onClick={ToggleSidebar} className="d-inline fs-3 sidebar-toggle">
            {showSidebar ? <HiMenuAlt3 className="fs-3" /> : <HiMenuAlt2 />}
          </div><h3>HEADER</h3>
        </div>


      </nav>
    </header>
    
   {/* } */}
    </>
  )
}

export default Header
