import React from 'react';
import NavBar from '../Components/NavBar'
import heroImg from '../Images/hero.svg'
import choice from '../Images/undraw_choice_re_2hkp.svg'
import complete from '../Images/undraw_completed_re_cisp.svg'
import expert from '../Images/undraw_experts_re_i40h.svg'
import { Link } from 'react-router-dom'

function HomePage() {
    return (
        <>

            <NavBar />
            <div className="container-fluid p-0 h-100" >
                <section className="container h-100">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-6 text-center mt-5 mt-lg-0">
                            <h1 style={{ fontWeight: 700 }}>Best Skills At Your Doorstep.</h1>
                            <p>Hire the best or Get Hired at a Glance</p>
                            <div>
                                <Link to="/login" className="btn text-white bg-purple py-2 px-4 m-2">LOG IN</Link>
                                <Link to="/signup" className="btn  btn-outline-purple py-2 px-4 m-2"> SIGN UP</Link>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center mt-3">
                            <img src={heroImg} width="100%" alt="" />
                        </div>

                    </div>


                </section>

                <section className="container-fluid bg-purple py-3">
                    <div className="container">
                        <div className="row justify-content-center align-items-center my-5 ">


                            <div className="col-12 col-md-4 p-2">
                                <div className=" p-3" style={{
                                    background: ' rgba(255,255,255,0.2)',
                                    backdropFilter: 'blur(5px)', borderRadius: '20px'
                                }}>
                                    <div>
                                        <img src={choice} width="100%" height="100px" alt="" />
                                    </div>
                                    <div>
                                        <h4 className="text-center text-capitalize mt-2 mb-1" >Lots of options to choose from</h4>
                                        <p>lorem ipsum dolor sit amet, consectetur ad,lorem ipsum dolor sit amet, consectetur adipiscing ,lorem ipsum dolor sit amet, consectetur adipiscing ipiscing</p>
                                    </div>
                                </div>
                            </div>




                            <div className="col-12 col-md-4 p-2">
                                <div className=" p-3" style={{
                                    background: ' rgba(255,255,255,0.2)',
                                    backdropFilter: 'blur(5px)', borderRadius: '20px'
                                }}>
                                    <div>
                                        <img src={expert} width="100%" height="100px" alt="" />
                                    </div>
                                    <div>
                                        <h4 className="text-center" >Top Careers</h4>
                                        <p>lorem ipsum dolor sit amet, consectetur ad,lorem ipsum dolor sit amet, consectetur adipiscing ,lorem ipsum dolor sit amet, consectetur adipiscing ipiscing</p>
                                    </div>
                                </div>
                            </div>




                            <div className="col-12 col-md-4 p-2">
                                <div className=" p-3" style={{
                                    background: ' rgba(255,255,255,0.2)',
                                    backdropFilter: 'blur(5px)', borderRadius: '20px'
                                }}>
                                    <div>
                                        <img src={complete} width="100%" height="100px" alt="" />
                                    </div>
                                    <div>
                                        <h4 className="text-center" >Get Hired</h4>
                                        <p>lorem ipsum dolor sit amet, consectetur ad,lorem ipsum dolor sit amet, consectetur adipiscing ,lorem ipsum dolor sit amet, consectetur adipiscing ipiscing</p>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>

                </section>


                <section class="newsletter">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="content">
                                    <h2>SUBSCRIBE TO OUR NEWSLETTER</h2>
                                    <p className="mt-n3" >Get The Latest Updates</p>
                                    <div class="input-group">
                                        <input type="email" class="form-control" placeholder="Enter your email" />
                                            <span class="input-group-btn">
                                                <button class="btn" type="submit">Subscribe Now</button>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </>
    );
}

export default HomePage;
