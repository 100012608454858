import React from 'react'
import TableHeadings from './TableHeadings'

function MemberRefs({refs}) {
    return (
        <div className="col-12 ">

        <div className="table-responsive" >
{
refs.length > 0 ?

<div className="overflow__scroll">
    <table className="table ">

<TableHeadings  headings={[
                    "id",
                    "name",
                    "note",
                    "relationship",
                    "create date",
                    "verify Date",
                    "phone",
                    "address"
                    ]} />

          <tbody>

          {
            refs.map((ref,index) =>(
              <tr key={index}>
              <th scope="row">{ref.id}  </th>
              <td scope="row">
              <img src="https://via.placeholder.com/30x30" className=" rounded-circle"  alt={ref.firstName} />
                  { ref.title }  { ref.firstName } { ref.lastName} { ref.isVerify==true ?
                  <span class="text-success fs-5">verified</span> 
                  : <a href="#"  className="text-primary">verify</a>}  
                  </td>
          <td scope="row">{ref.note}  </td>
          <td scope="row">{ref.relationship}  </td>
          {/* <td scope="row">{ ref.createDate.slice(0,10) || "N/A"}  </td>
          <td scope="row">{ ref.verifyDate.slice(0,10) || "N/A"}  </td> */}
          {/* <td scope="row">{ref.verifyDate.slice(0,10)}  </td> */}
          <td scope="row">{ref.phone  || "N/A" }  </td>
          <td scope="row">{ref.address  || "N/A" }  </td>
          {/* <td scope="row">{ref.debitCredit}  </td> */}

                 </tr>
                 // {console.log(skill.note)}
              ))

          }


</tbody>
          
            </table>
</div>
            

:<h5 className="text-danger"><u>NO DATA TO DISPLAY</u></h5>
}
        </div>
    </div>
    )
}

export default MemberRefs
