import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import '../Styles/table.scss'
import requests from '../Helpers/requests'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { FaCheckCircle, FaStar } from 'react-icons/fa';
import TableHeadings from '../Components/TableHeadings'
import Modal from '../Components/Modal'

function Skills() {
    const [modal, setModal] = useState(false)
    const toggleModal = () => { setModal(!modal); }

    const navigate = useNavigate()

    const [isLoading, setLoading] = useState(true)

    const [skills, setSkills] = useState([])

    const checkReference = (userId, skillId) => {
        axios.get(`${requests.getMembers}/${userId}`).then((response) => {
            if (response.data.refs.length >= 2) {
                navigate(`/verify-member-skill/${userId}/${skillId}`)
            }
            else {
                toggleModal()
            }
        });




    }

    // const getReferenceLength = axios.get(`requests.getMembers}/${userId}`);
    const getSkills = axios.get(`${requests.getSkills}`);

    useEffect(() => {
        axios.get(`${requests.getSkills}`).then((response) => {
            setSkills(response.data)
            // console.log(response.data);
            setLoading(false)
        })

    }, [])
    return (
        <div className="container table-responsive my-3 position-relative" >
            {
                isLoading ?
                    <div className="loader">
                        {/* <h5>Content Loading</h5> */}
                        <Loader
                            type="ThreeDots"
                            color="#83418b"
                            height={100}
                            width={100}
                            timeout={0} //0 secs
                        />
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-center align-items-center my-3">
                            <h3 className="text-center d-inline" >SKILLS </h3> <span class="badge badge-pill">{skills.length}</span>
                        </div>


                        <table>


                            <TableHeadings headings={[
                                "id",
                                "BUSINESS NAME",
                                "RATING",
                                "note",
                                "ADDRESS",
                                "PHONE",
                                "EMAIL",
                                "CATEGORY",
                            ]} />


                            <tbody>

                                {
                                    skills.map((skill, index) => (
                                        <tr key={index}>
                                            <th scope="row">{skill.id}  </th>

                                            <td>
                                                {skill.businessName == "" ? "N/A" : skill.businessName}

                                                {

                                                    skill.isVerify
                                                        ? <> <FaCheckCircle className="verified__mark" /> </>
                                                        : <div onClick={() => checkReference(skill.memberId, skill.id)} className="text-primary pointer"><u>verify </u></div>

                                                }

                                            </td>


                                            <td>{skill.averageRating} <FaStar /></td>
                                            <td>{skill.note}</td>
                                            <td>{skill.contactAddress || "N/A"}</td>
                                            <td>{skill.contactPhone || "N/A"}</td>
                                            <td>{skill.contactEmail || "N/A"}</td>
                                            <td> {skill.category.title}</td>
                                        </tr>

                                    ))

                                }

                            </tbody>
                        </table>
                    </>
            }
            {modal ? <Modal content="you have to verify at least 2 references before you can verify skills" toggleModal={toggleModal} /> : ""}

        </div >
    )
}


export default Skills
