// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDV8gBZMg7_2-G0kj_yj6_fFeelb7mO6dM",
    authDomain: "verifiedskills-53982.firebaseapp.com",
    projectId: "verifiedskills-53982",
    storageBucket: "verifiedskills-53982.appspot.com",
    messagingSenderId: "244856063537",
    appId: "1:244856063537:web:b22952316e9df288def710"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();