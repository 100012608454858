import React from 'react'

function TableHeadings({headings}) {
    return (
        <thead>

                
        <tr>

            {
                headings.map((heading)=>(
                    <th className={heading} > {heading}</th>
                ))
            }
          {/* <th >ID</th>
          <th >amount Agreed </th>
          <th >amount Paid</th>
          <th >Payment</th>
          <th >Seal</th>
          <th >Work Done</th>
          <th >Note</th>
          <th >create date</th>
          <th >phone</th>
          <th >address</th> */}
        
      </tr>
      </thead>
    )
}

export default TableHeadings
