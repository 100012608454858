import React, { useState } from 'react'
import { FaCheckCircle, FaStar } from 'react-icons/fa';
import TableHeadings from './TableHeadings'
import Modal from './Modal'
import { Link } from "react-router-dom"

// import {getSkillId,VerifySkill} from '../POST/VerifySkill'

function MemberSkills({ skills, refCount, userId }) {
  const [modal, setModal] = useState(false)
  const toggleModal = () => { setModal(!modal); }

  return (
    <div className="col-12 ">

      <div className="table-responsive" >

        {
          skills.length > 0 ?

            <div className="overflow__scroll">
              <table className="table overflow__scroll">


                <TableHeadings headings={[
                  "id",
                  "BUSINESS NAME",
                  "RATING",
                  "note",
                  "ADDRESS",
                  "PHONE",
                  "EMAIL",
                  "CATEGORY"
                ]} />


                <tbody>

                  {
                    skills.map((skill, index) => (
                      <tr key={index}>
                        <th scope="row">{skill.id}  </th>
                        <td>{skill.businessName == "" ? "N/A" : skill.businessName}
                          {skill.isVerify == true ?
                            <> <FaCheckCircle className="verified__mark" /> </>
                            : refCount >= 2 ?
                              <Link to={`/verify-member-skill/${userId}/${skill.id}`} className="text-primary"><u >verify</u></Link>
                              : <span className="text-primary pointer" onClick={toggleModal} ><u>verify</u></span>
                          }</td>
                        <td>{skill.averageRating} <FaStar /></td>

                        <td>{skill.note}</td>
                        <td>{skill.contactAddress || "N/A"}</td>
                        <td>{skill.contactPhone || "N/A"}</td>
                        <td>{skill.contactEmail || "N/A"}</td>
                        <td> {skill.category.title}</td>
                      </tr>
                      // {console.log(skill.note)}
                    ))

                  }

                </tbody>

              </table>
              {modal ? <Modal content="you have to verify at least 2 references before you can verify skills" toggleModal={toggleModal} /> : ""}
            </div>



            : <h5 className="text-danger"><u>NO DATA TO DISPLAY</u></h5>
        }
      </div>
    </div>
  )
}

export default MemberSkills
