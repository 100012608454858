import React, { useState,useContext,useEffect } from 'react'
import { Outlet } from 'react-router';
import Validate from '../Auth/Validate';
import { AuthContext } from '../Auth/AuthContext'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import {useNavigate} from 'react-router-dom'
function ProtectedRoutes() {
    const { isAuth, setIsAuth } = useContext(AuthContext)
    const [showSidebar, setShowSidebar] = useState(false)
    const [showSidebar1, setShowSidebar1] = useState("false")

const navigate = useNavigate()
    useEffect(() => {
        // const data = (localStorage.getItem('isAuthFromLocalStorage'));
        if (localStorage.getItem('isAuthFromLocalStorage')) {
          setIsAuth(JSON.parse(localStorage.getItem('isAuthFromLocalStorage')))
        }
      }, [])
    
      useEffect(() => {
        if (!localStorage.getItem('isAuthFromLocalStorage')) {
            navigate('/login')
            // setIsAuth(JSON.parse(localStorage.getItem('isAuthFromLocalStorage')))
          }
        // localStorage.setItem('isAuthFromLocalStorage', JSON.stringify(isAuth))
      })

    return (
        isAuth ?<div className="App d-flex h-100"> 
        <Sidebar showSidebar={showSidebar}  setShowSidebar={setShowSidebar} ToggleSidebarOnOverlayClick={() => setShowSidebar1(!showSidebar1)} />
        <div className="d-flex flex-column w-100 " >

{isAuth ? <Header ToggleSidebar={() => setShowSidebar(!showSidebar)} showSidebar={showSidebar} showSidebar={showSidebar} /> : null}

         <Outlet /> 
        </div>
        </div>
        
        : <Validate />
    )
}

export default ProtectedRoutes
