import React,{useState,useEffect} from 'react'
import axios from 'axios';
import {Link} from 'react-router-dom'
import '../Styles/table.scss'
import requests from '../Helpers/requests'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


function Jobs() {
    const [isLoading, setLoading] = useState(true)

    const [jobs,setJobs] = useState([]) 
    
    useEffect(()=>{
        axios.get(`${requests.getJobs}`).then((response)=>{
            setJobs(response.data)
            setLoading(false)
        })
    },[])
        return (
            <div className="container table-responsive my-3 position-relative" >
            {
            isLoading ?   
            <div className="loader">
                 {/* <h5>Content Loading</h5> */}
            <Loader
            type="ThreeDots"
            color="#83418b"
            height={100}
            width={100}
            timeout={0} //0 secs
            />
            </div>
            :
            <>
                           <div className="d-flex justify-content-center align-items-center my-3">
                           <h3 className="text-center d-inline" >JOBS </h3> <span class="badge badge-pill">{jobs.length}</span>
                           </div>
    
            
                        <table>
                            <thead>
                                <tr>
                                <th scope="col">ID</th>
                        <th scope="col">TITLE</th>
                        <th scope="col">NOTE</th>
                        <th scope="col">member Id</th>
                        <th scope="col">Create Date</th>
                        <th scope="col">Expiry Date</th>
                        <th scope="col">requirements</th>
                        <th scope="col">status</th>
                                </tr>
                            </thead>
                            <tbody>
                           
                                {
                                    jobs.map((job,index) =>(
                                        <tr key={index}>
                                        <th scope="row">{job.id}  </th>
                                        <td scope="row">{job.title}  </td>
            <td scope="row">{job.note}  </td>
                                        <td scope="row">{job.userId}  </td>
            <td scope="row">{job.createDate}  </td>
            <td scope="row">{job.expiredDate}  </td>
            <td scope="row">{job.requirements}  </td>
            <td scope="row">{job.isExpired? <span className="text-danger" >Expired</span> :<span className="text-success" >Active</span> }  </td>
                                       </tr>
                    
                                    ))
                                }
                               
                            </tbody>
                        </table>
                        </>
                 }
            
                    </div>
        )
    }

export default Jobs
