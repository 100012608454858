import React, { useEffect, useState } from 'react'
import './App.css';
import Home from './Pages/Home';
import About from './Pages/About';
import Sidebar from './Components/Sidebar';
import Header from './Components/Header';
import { Route, Routes } from 'react-router-dom';
import Members from './Pages/Members';
import MemberDetails from './Pages/MemberDetails';
import Abuses from './Pages/Abuses';
import ApplyJobs from './Pages/ApplyJobs';
import AppUsers from './Pages/AppUsers';
import Jobs from './Pages/Jobs';
import Payments from './Pages/Payments';
import References from './Pages/References';
import Skills from './Pages/Skills';
import Privacy from './Pages/Privacy';
import Login from './Auth/Validate'
import VerifyMemberSkill from './POST/VerifyMemberSkill'
import VerifyMember from './POST/VerifyMember';
import Category from './Pages/Category';
import NewCategory from './POST/NewCategory';
import MemberStatus from './POST/MemberStatus';
import ProtectedRoutes from './Helpers/ProtectedRoutes';
import Validate from './Auth/Validate'
import { AuthContext } from './Auth/AuthContext'
import HomePage from './Pages/HomePage';

function App() {

  const [isAuth, setIsAuth] = useState(false)

  const [showSidebar, setShowSidebar] = useState(false)
  const [showSidebar1, setShowSidebar1] = useState("false")
  // const ToggleSidebar = () => {
  //   setShowSidebar(!showSidebar)
  // }
  // const ToggleSidebarOnOverlayClick = () => {
  //   setShowSidebar1(!showSidebar1)
  // }
  // useEffect(() => {
  //   const data = (localStorage.getItem('isAuthFromLocalStorage'));
  //   if (localStorage.getItem('isAuthFromLocalStorage')) {
  //     setIsAuth(JSON.parse(localStorage.getItem('isAuthFromLocalStorage')))
  //   }
  // }, [])

  // useEffect(() => {
  //   localStorage.setItem('isAuthFromLocalStorage', JSON.stringify(isAuth))
  // })

  return (


    <>
      <AuthContext.Provider value={{ isAuth, setIsAuth }}>
        <div className="App d-flex h-100">
          {/* {isAuth ? <Sidebar showSidebar={showSidebar} ToggleSidebarOnOverlayClick={() => setShowSidebar1(!showSidebar1)} /> : null} */}

          <div className="d-flex flex-column w-100 " >

            {/* {isAuth ? <Header ToggleSidebar={() => setShowSidebar(!showSidebar)} showSidebar={showSidebar} /> : null} */}



            <Routes>
              <Route path="/login" element={<Validate />} />
              <Route path="/signup" element={<Validate />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/" element={<HomePage />} />

              <Route element={<ProtectedRoutes />}>
                <Route path="/dashboard" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/members" element={<Members />} />
                <Route path="/member/:userId" element={<MemberDetails />} />
                <Route path="/abuses" element={<Abuses />} />
                <Route path="/apply-jobs" element={<ApplyJobs />} />
                <Route path="/app-users" element={<AppUsers />} />
                <Route path="/jobs" element={<Jobs />} />
                <Route path="category" element={<Category />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/references" element={<References />} />
                <Route path="/skills" element={<Skills />} />
                <Route path="/newCategory" element={<NewCategory />} />
                <Route path="/verify-member-skill/:userId/:skillId" element={<VerifyMemberSkill />} />
                <Route path="/verifyMember/:userId" element={<VerifyMember />} />
                <Route path="/verifyMemberStatus/:userId" element={<MemberStatus />} />
              </Route>

            </Routes>

          </div>
        </div>
      </AuthContext.Provider>
    </>
  );
}

export default App;
