import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { FaCheckCircle, FaPhoneAlt, FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import '../Styles/table.scss'
import requests from '../Helpers/requests'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import TableHeadings from '../Components/TableHeadings'

function Members() {
    const [isLoading, setLoading] = useState(true)

    const [member, setMember] = useState([])
    useEffect(() => {
        axios.get(requests.getMembers).then(response => {
            setMember(response.data)
            setLoading(false) //stop loading when data is fetched

            // console.table(member)
        })

    }, []);


    //     const tableHeaders = [];
    // for (var key in member[0]) {
    //     tableHeaders.push(key);
    // }

    // console.log(keys)




    return (
        <div className="container members table-responsive my-3 position-relative" >
            {
                isLoading ?
                    <div className="loader">
                        {/* <h5>Content Loading</h5> */}
                        <Loader
                            type="ThreeDots"
                            color="#83418b"
                            height={100}
                            width={100}
                            timeout={0} //0 secs
                        />
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-center align-items-center my-3">
                            <h3 className="text-center d-inline" >MEMBERS </h3> <span class="badge badge-pill">{member.length}</span>
                        </div>
                        <table>

                            <TableHeadings headings={[
                                "id",
                                "NAME",
                                "EMAIL", "PHONE",
                                "MEANS OF VERIFICATION",
                                " ",
                            ]} />

                            <tbody>

                                {
                                    member.map((mem, index) => (
                                        <tr key={index}>
                                            <th scope="row">{mem.id}</th>
                                            <td>
                                                <Link to={`/member/${mem.id}`} className="username">  <div>  <img src="https://via.placeholder.com/350x150" className="table__img rounded-circle" alt={mem.firstName} /> {mem.firstName ? mem.firstName : "N/A"} {mem.lastName} {(mem.isVerify === true) ? <FaCheckCircle className="verified__mark" /> : ""}</div></Link>
                                            </td>
                                            <td>{mem.email} </td>
                                            <td>{mem.phone} </td>
                                            <td> {mem.isVerify ? "Phone" : <a href={`tel:${mem.phone}`}>Call to verify <FaPhoneAlt /></a>} </td>

                                            {/* <td><div className="table__button rounded-circle" ><Link to={`/member/${mem.id}`} className=" ">  <FaInfoCircle /></Link> </div></td> */}

                                        </tr>

                                    ))
                                }

                            </tbody>
                        </table>
                    </>
            }

        </div>

    )
}

export default Members
