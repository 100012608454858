import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import '../Styles/table.scss'
import requests from '../Helpers/requests'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


function Category() {
    const [isLoading, setLoading] = useState(true)

    const [categories, setCategory] = useState([])

    useEffect(() => {
        axios.get(`${requests.getCategory}`).then((response) => {
            setCategory(response.data)
            setLoading(false)
        })
    }, [])
    return (
        <div className="container table-responsive my-3 position-relative" >
            {
                isLoading ?
                    <div className="loader">
                        {/* <h5>Content Loading</h5> */}
                        <Loader
                            type="ThreeDots"
                            color="#83418b"
                            height={100}
                            width={100}
                            timeout={0} //0 secs
                        />
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-center align-items-center my-3">
                            <h3 className="text-center d-inline" >CATEGORIES </h3> <span class="badge badge-pill">{categories.length}</span>
                            <br />
                        </div>
                        <div className="my-3 text-center" >
                            <Link to="/newCategory">Add New Category</Link>
                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">TITLE</th>
                                    <th scope="col">top Category</th>
                                    <th scope="col">NOTE</th>
                                    <th scope="col">Created by</th>
                                    <th scope="col">Create Date</th>
                                    <th scope="col">Expiry Date</th>
                                    <th scope="col">requirements</th>
                                    <th scope="col">status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    categories.map((category, index) => (
                                        <tr key={index}>
                                            <th scope="row">{category.id}  </th>
                                            <td scope="row">{category.title}  </td>
                                            <td scope="row">{category.topCategory}  </td>
                                            <td scope="row">{category.note}  </td>
                                            <td scope="row">{category.createdBy}  </td>
                                            <td scope="row">{category.createDate}  </td>
                                            <td scope="row">{category.expiredDate}  </td>
                                            <td scope="row">{category.requirements}  </td>
                                            <td scope="row">{category.isExpired ? <span className="text-danger" >Expired</span> : <span className="text-success" >Active</span>}  </td>
                                        </tr>

                                    ))

                                }

                            </tbody>
                        </table>
                    </>
            }

        </div>
    )
}

export default Category
