import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useParams } from 'react-router-dom'
import '../Styles/table.scss'
import '../Styles/memberDetails.scss'
import requests from '../Helpers/requests'

import MemberDetailsTop from '../Components/MemberDetailsTop';
import MemberSkills from '../Components/MemberSkills';
import MemberJobs from '../Components/MemberJobs';
import MemberPayments from '../Components/MemberPayments';
import MemberRefs from '../Components/MemberRefs';
import MemberRequests from '../Components/MemberRequests';
import MemberAbuses from '../Components/MemberAbuses';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import VerifySkills from '../Forms/VerifySkills';

function MemberDetails() {

    const { userId } = useParams()
    const [memberDetails, setMemberDetails] = useState([])
    const [isLoading, setLoading] = useState(true)

    const [refCount, setRefCount] = useState(0)


    useEffect(() => {
        axios.get(`${requests.getMembers}/${userId}`).then((response) => {
            setMemberDetails(response.data)
            // setSkills(response.data.skills)
            // setJobs(response.data.jobs)
            // setPayments(response.data.payments)
            // setRefs(response.data.refs)
            // setMemberRequests(response.data.requests)

            setLoading(false) //stop loading when data is fetched

            // CHECK IF REFERENCES ARE MORE THAN 2
            response.data.refs.map((memberRef, index) => (
                memberRef.isVerify ? setRefCount(refCount + index + 1) : ""
            ))
        })
    }, [userId])


    return (
        <div className="container  " >
            {
                isLoading ?
                    <div className="loader">

                        <Loader
                            type="ThreeDots"
                            color="#83418b"
                            height={100}
                            width={100}
                            timeout={0} //0 secs
                        />
                    </div>

                    :
                    <div className="row  mt-3">

                        <MemberDetailsTop memberDetails={memberDetails} userId={userId} />

                        <div className="col-12">
                            <Tabs>
                                <TabList>
                                    <Tab >SKILLS  <span class="badge badge-pill ">{memberDetails.skills.length}</span> </Tab>
                                    <Tab>JOBS <span class="badge badge-pill ">{memberDetails.jobs.length}</span> </Tab>
                                    <Tab>PAYMENTS <span class="badge badge-pill ">{memberDetails.payments.length}</span> </Tab>
                                    <Tab>REFERENCES <span class="badge badge-pill ">{memberDetails.refs.length}</span> </Tab>
                                    <Tab>REQUESTS <span class="badge badge-pill ">{memberDetails.requests.length}</span> </Tab>
                                    <Tab>ABUSES <span class="badge badge-pill ">{memberDetails.abuses.length}</span> </Tab>
                                    <Tab>REPORTS <span class="badge badge-pill ">{memberDetails.reports.length}</span> </Tab>
                                    <Tab>SERVICES <span class="badge badge-pill ">{memberDetails.services.length}</span> </Tab>
                                    <Tab>CERTIFICATES </Tab>
                                    <Tab>JOB APPS.</Tab>
                                </TabList>

                                <TabPanel>
                                    {/* skills */}
                                    {


                                    }
                                    <MemberSkills skills={memberDetails.skills} userId={userId} refCount={refCount} />

                                    {/* ------------------------ */}
                                </TabPanel>
                                <TabPanel>
                                    {/* jobs */}
                                    <MemberJobs jobs={memberDetails.jobs} />

                                    {/* ------------------- */}
                                </TabPanel>
                                <TabPanel>
                                    {/* payments */}
                                    <MemberPayments payments={memberDetails.payments} />
                                    {/* ------------------- */}
                                </TabPanel>
                                <TabPanel>
                                    {/* refs */}
                                    <MemberRefs refs={memberDetails.refs} />
                                    {/* ------------------- */}
                                </TabPanel>
                                <TabPanel>
                                    {/* requests */}
                                    <MemberRequests requests={memberDetails.requests} />
                                    {/* ------------------- */}
                                </TabPanel>
                                <TabPanel>
                                    {/* <h2>Any content 2</h2> */}
                                    <MemberAbuses abuses={memberDetails.abuses} />
                                </TabPanel>
                            </Tabs>


                        </div>
                        {/* <div className="col-12">
                   <VerifySkills />
               </div> */}
                    </div>


            }



        </div>

    )
}

export default MemberDetails
