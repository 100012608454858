import React, { useState, useContext, useEffect } from 'react';
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from "react-router-dom"
import 'react-pro-sidebar/dist/css/styles.css';
import '../Styles/sidebar.scss'
import logo from '../Images/logo.svg'
// import '../Styles/sidebar.scss'
import { FaBusinessTime, FaBrain, FaHandSparkles, FaThumbsUp, FaTruck, FaAngry, FaCogs, FaCreditCard, FaUserFriends, FaCertificate, FaUserAlt, FaHome, FaBriefcase, FaSignOutAlt } from 'react-icons/fa';
import { SiScrutinizerci } from 'react-icons/si';
import { CgDanger } from 'react-icons/cg';
import { MdCategory } from 'react-icons/md';
import { VscReferences } from 'react-icons/vsc';
import { AuthContext } from '../Auth/AuthContext'

function Sidebar({ showSidebar }) {
  const location = useLocation()
  const { isAuth, setIsAuth } = useContext(AuthContext)
  // const [isGeneralPage, setIsGeneralPage ] = useState(false)
  const [checkPath, setCheckPath] = useState(false)

const logout =()=>{ 
  localStorage.removeItem('isAuthFromLocalStorage')
  window.location.reload();

}

  useEffect(() => {
    if (location.pathname == "/privacy") {
      setCheckPath(true)
    }
    else if (location.pathname == "/") {
      setCheckPath(true)
    }
    else {
      setCheckPath(false)
    }
  }, [])
  return (
    <>
      {/* {
       checkPath ?
          ""
          : */}
      <div>

        <ProSidebar collapsed={showSidebar} width="200px" breakPoint="md" toggled={showSidebar} className="fixed-top">
          <SidebarHeader>
            <div className="text-center">
            <img src={logo} width="40%" alt="" />
            </div>
            {
              // showSidebar ? <h4 className="text-center">VS</h4> : <h4 className="text-center">Verified Skill</h4>
              // showSidebar ? <h3 className="text-center logo"><SiScrutinizerci /></h3> : <h4 className="text-center">Verified Skills</h4>
            }
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square" iconShape="circle">
              <MenuItem icon={<FaHome />}>Main  <Link to="/dashboard" onClick={showSidebar} /></MenuItem>
              <MenuItem icon={<FaUserFriends />}>Members <Link to="/members" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<FaHandSparkles />}>Skill <Link to="/skills" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<FaBusinessTime />}>Job <Link to="/jobs" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<MdCategory />}>Category <Link to="/category" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<FaCreditCard />}>Payments <Link to="/payments" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<FaCogs />}>Payment Settings <Link to="/payment-settings" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<FaBriefcase />}>Apply Jobs <Link to="/apply-jobs" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<FaUserAlt />}>App User <Link to="/app-users" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<VscReferences />}>References <Link to="/references" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<FaCertificate />}>Certificate <Link to="/certificates" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<FaBrain />}>Experience <Link to="/experience" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<CgDanger />}>Abuse  <Link to="/abuses" onClick={showSidebar} /></MenuItem>
              <MenuItem icon={<FaAngry />}>Penalty <Link to="/penalty" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<FaTruck />}>Service Delivery <Link to="/service-delivery" onClick={showSidebar} /> </MenuItem>
              <MenuItem icon={<FaThumbsUp />}>Subscription <Link to="/subscriptions" onClick={showSidebar} /> </MenuItem>

              {/* <SubMenu title="Utlities" icon={<FaHeart />}>
<MenuItem>Comp</MenuItem>
<MenuItem>Comp</MenuItem>
</SubMenu> */}

            </Menu>

          </SidebarContent>
          <SidebarFooter>

            {
              showSidebar ? <h5 onClick={logout} className="text-center text-white py-2 si"  ><FaSignOutAlt /> </h5> : <h5 onClick={logout} className="text-center text-white py-2" ><FaSignOutAlt /> Logout</h5>
            }
          </SidebarFooter>
        </ProSidebar>
      </div>
      {/* } */}
    </>
  )
}

export default Sidebar
