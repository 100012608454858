import React, { useState } from 'react'
import { FaTimesCircle, FaCheckCircle, FaAddressCard, FaStar, FaPhoneAlt, FaInfoCircle } from 'react-icons/fa';
import { MdSchool } from 'react-icons/md';
import { Link } from 'react-router-dom'

function MemberDetailsTop({ memberDetails, userId }) {
    const [isVerifyModal, setIsVerifyModal] = useState(false)
    return (
        <div className="col-12 bg-white rounded">
            <div className="text-center">
                <img src="https://via.placeholder.com/120x120" alt="nn" className="rounded-circle" />
                <br />
                <h4 id={`ski${memberDetails.id}llo`}>
                    {memberDetails.firstName} {memberDetails.middleName} {memberDetails.lastName} {(memberDetails.isVerify == true) ? <FaCheckCircle className="verified__mark" /> : <Link to={`/verifyMember/${userId}`} className="fs-5 fw-1">verify</Link>}
                </h4>

                {memberDetails.isCompany ? <p>(Company)</p> : ""}
                <p>{memberDetails.email}  {!(memberDetails.isEmailVerify) ? " " : <a href="#" className="text-primary">verify</a>}   </p>
                <p>{memberDetails.phone}   </p>
                <p > <FaAddressCard /> {memberDetails.address == null ? "N'A" : memberDetails.address}    ---- <MdSchool /> {memberDetails.highestEdu == null ? "N'A" : memberDetails.highestEdu}  </p>
                {/* <p>   Joined on {  splitString(memberDetails.createDate,"T")} </p> */}
                <p>   Joined on {memberDetails.createDate} </p>
            </div>
        </div >
    )
}

export default MemberDetailsTop
