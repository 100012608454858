import React from 'react'
import '../Styles/modal.scss'
import {MdClose} from 'react-icons/md';

function Modal({toggleModal,content}) {
    return (
       <>
     
<section  className="modal">
      <div>  {content} 
      <MdClose onClick={toggleModal} className="modal__close" />
      </div>
    </section>
   
    
  </>
    )
}
{/* <Popup trigger={<button> Trigger</button>} position="right center">
<div>Popup content here !!</div>
</Popup> */}
export default Modal
