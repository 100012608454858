import React from 'react'
import TableHeadings from './TableHeadings'

function MemberAbuses({ abuses }) {
    return (
        <div className="col-12 ">

            <div className="table-responsive " >
                {

                    abuses.length > 0 ?

                        <div className="overflow__scroll">
                            <table className="table ">

                                <TableHeadings headings={[
                                    "id",
                                    "reporter id",
                                    "note",
                                    "Acted on",
                                    "Acted on by",
                                    "action Date",
                                    "create Date"
                                ]} />
                                <tbody>

                                    {
                                        abuses.map((abuse, index) => (
                                            <tr key={index}>
                                                <th scope="row">{abuse.id}  </th>
                                                <td scope="row">{abuse.reporterId}  </td>

                                                <td scope="row">{abuse.note}  </td>
                                                <td scope="row">{abuse.isActedOn}  </td>
                                                <td scope="row">{abuse.actedOnBy}  </td>
                                                <td scope="row">{abuse.actionDate.slice(0, 10)}  </td>
                                                <td scope="row">{abuse.createDate.slice(0, 10)}  </td>
                                            </tr>
                                        ))

                                    }



                                </tbody>

                            </table>
                        </div>


                        : <h5 className="text-danger"><u>NO DATA TO DISPLAY</u></h5>

                }
            </div>
        </div>
    )
}

export default MemberAbuses
