import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { FaTimesCircle,FaCheckCircle,FaPhoneAlt,FaInfoCircle } from 'react-icons/fa';
import {Link} from 'react-router-dom'
import '../Styles/table.scss'
import requests from '../Helpers/requests'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


function ApplyJobs() {
    const [isLoading, setLoading] = useState(true)

    const [applyJobs,setApplyJobs] = useState([]) 
    
    useEffect(()=>{
        axios.get(`${requests.getAbuses}`).then((response)=>{
            setApplyJobs(response.data)
            setLoading(false)
        })
    },[])
        return (
            <div className="container table-responsive my-3 position-relative" >
            {
            isLoading ?   
            <div className="loader">
                 {/* <h5>Content Loading</h5> */}
            <Loader
            type="ThreeDots"
            color="#83418b"
            height={100}
            width={100}
            timeout={0} //0 secs
            />
            </div>
            :
            <>
                           <div className="d-flex justify-content-center align-items-center my-3">
                           <h3 className="text-center d-inline" >APPLY JOBS </h3> <span class="badge badge-pill">{applyJobs.length}</span>
                           </div>
    
            
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">job ID</th>
                                    <th scope="col">note</th>
                                    <th scope="col">applicant Id</th>
                                    <th scope="col">date Created</th>
                                    <th scope="col"> engage Date</th>
                                </tr>
                            </thead>
                            <tbody>
   
                                {
                                    applyJobs.map((applyJob,index) =>(
                                        <tr key={index}>
                                        <th scope="row">{applyJob.id}</th>
                                        <td>{applyJob.note} </td>
                                        <td>{applyJob.applicantId} </td>
                                        <td>{applyJob.dateCreated} </td>
                                        <td>{applyJob.engageDate} </td>
            
                                        {/* <td><div className="table__button rounded-circle" ><Link to={`/member/${applyJob.id}`}  className=" ">  <FaInfoCircle /></Link> </div></td> */}
            
                                        </tr>
                    
                                    ))
                                }
                               
                            </tbody>
                        </table>
                        </>
                 }
            
                    </div>
        )
    }

export default ApplyJobs
