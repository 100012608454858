import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import '../Styles/table.scss'
import requests from '../Helpers/requests'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function References() {
    const [isLoading, setLoading] = useState(true)

    const [references, setReferences] = useState([])

    useEffect(() => {
        axios.get(`${requests.getReferences}`).then((response) => {
            setReferences(response.data)
            setLoading(false)
        })
    }, [])
    return (
        <div className="container table-responsive my-3 position-relative" >
            {
                isLoading ?
                    <div className="loader">
                        {/* <h5>Content Loading</h5> */}
                        <Loader
                            type="ThreeDots"
                            color="#83418b"
                            height={100}
                            width={100}
                            timeout={0} //0 secs
                        />
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-center align-items-center my-3">
                            <h3 className="text-center d-inline" >REFERENCES </h3> <span class="badge badge-pill">{references.length}</span>
                        </div>


                        <table>
                            <thead>
                                <tr>

                                    <th scope="col">ID</th>
                                    <th scope="col">name </th>
                                    <th scope="col">NOTE</th>
                                    <th scope="col">relationship</th>
                                    <th scope="col">create date</th>
                                    <th scope="col">verify date</th>
                                    <th scope="col">phone</th>
                                    <th scope="col">address</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    references.map((reference, index) => (
                                        <tr key={index}>
                                            <th scope="row">{reference.id}  </th>
                                            <td scope="row">
                                                <img src="https://via.placeholder.com/30x30" className=" rounded-circle" alt={reference.firstName} />
                                                {reference.title}  {reference.firstName} {reference.lastName} {reference.isVerify == true ?
                                                    <span class="text-success fs-5">verified</span>
                                                    : <Link to={`/verifyReference/${reference.id}`} className="text-primary">verify</Link>}
                                            </td>
                                            <td scope="row">{reference.note}  </td>
                                            <td scope="row">{reference.relationship}  </td>
                                            {/* <td scope="row">{ reference.createDate.slice(0,10) || "N/A"}  </td>
<td scope="row">{ reference.verifyDate.slice(0,10) || "N/A"}  </td> */}
                                            {/* <td scope="row">{reference.verifyDate.slice(0,10)}  </td> */}
                                            <td scope="row">{reference.phone || "N/A"}  </td>
                                            <td scope="row">{reference.address || "N/A"}  </td>
                                            {/* <td scope="row">{reference.debitCredit}  </td> */}

                                        </tr>
                                        // {console.log(skill.note)}
                                    ))

                                }


                            </tbody>
                        </table>
                    </>
            }

        </div>
    )
}

export default References
