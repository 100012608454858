const baseUrl = "https://skillo.justwebtech.com/api"

const requests = {
    getMembers: `${baseUrl}/member`,
    getAbuses: `${baseUrl}/abuse`,
    getApplyJobs: `${baseUrl}/applyjob`,
    getAppUsers: `${baseUrl}/appuser`,
    getJobs: `${baseUrl}/job`,
    getPayments: `${baseUrl}/payment`,
    getReferences: `${baseUrl}/reference`,
    getSkills: `${baseUrl}/skill`,
    getPenalties: `${baseUrl}/penalty`,
    getCategory: `${baseUrl}/skill/categories/`,
    // https://skillo.justwebtech.com/api/skill/categories/
}

export default requests