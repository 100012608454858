import React, { useState, useContext, useEffect } from 'react';
// import {useNavigate} from 'react-router-dom'
import '../Styles/Validate.scss';
import { auth } from '../Helpers/firebaseConfig'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import { AuthContext } from './AuthContext'
import NavBar from '../Components/NavBar'
import signInImg from '../Images/undraw_sign_in_re_o58h.svg'
import signUpImg from '../Images/undraw_access_account_re_8spm.svg'
import { useNavigate } from 'react-router-dom'
import loader from '../Images/loader.svg'

// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

// const auth = getAuth();
// createUserWithEmailAndPassword(auth, email, password)
//   .then((userCredential) => {
//     // Signed in 
//     const user = userCredential.user;
//     // ...
//   })
//   .catch((error) => {
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     // ..
//   });





function Login() {
    const { isAuth, setIsAuth } = useContext(AuthContext)
    const navigate = useNavigate()
    const [isLoginForm, setIsLoginForm] = useState(true)
    const [formStatus, setFormStatus] = useState([])
    const [email, setEmail] = useState('N/A')
    const [password, setPassword] = useState('N/A')
    const [isLoading, setIsLoading] = useState(false)

    // const handleValidateError=()=>{ 

    // }
    useEffect(() => {
        if (localStorage.getItem('isAuthFromLocalStorage')) {
            navigate('/dashboard')
            // setIsAuth(JSON.parse(localStorage.getItem('isAuthFromLocalStorage')))
        }
    })

    const signUp = (e) => {
        e.preventDefault()
        setIsLoading(true)
        createUserWithEmailAndPassword(auth, email, password)
            .then(response => {
                // setIsLoading(true)
                setIsAuth(true)
                localStorage.setItem('isAuthFromLocalStorage', JSON.stringify(response.user))
                setTimeout(navigate("/dashboard"), 2000)
            })
            .catch(err => { setFormStatus(err.code); })
            .then(
                setTimeout(setIsLoading(false), 2000)

            )

    }

    const logIn = (e) => {
        e.preventDefault()
        setIsLoading(true)
        signInWithEmailAndPassword(auth, email, password)
            .then(userCredential => {
                // setIsAuth(true)
                if (userCredential._tokenResponse.registered) {
                    setIsAuth(true)
                    localStorage.setItem('isAuthFromLocalStorage', JSON.stringify(userCredential.user))
                    setTimeout(navigate("/dashboard"), 2000)

                }
                // console.log(userCredential._tokenResponse.registered)
            })
            .catch(err => { setFormStatus(err.code); })
            .then(
                setTimeout(setIsLoading(false), 2000)

            )
    }

    return (
        <>
            {/* <NavBar /> */}
            <div className="form  row" >

                <div className="col-12 col-md-7 vh-100 d-none d-md-grid login__left ">
                    <h3 className="text-center my-2" >

                    </h3>
                    <img src={!isLoginForm ? signInImg : signUpImg} width="100%" height="80%" alt="" />
                </div>
                <div className="col-12 col-md-5 vh-100  login__right ">
                    <h2>
                        {isLoginForm ? "  Log In" : "Sign Up "}

                    </h2>
                    {
                        isLoginForm ?
                            // login form
                            <div className=" login-form p-3 " >
                                {/* <h3 className="text-center my-2" >VERIFIED SKILL </h3> */}
                                <div className="form_group">
                                    <label for="email" >EMAIL:</label>
                                    <input onInput={(e) => setEmail(e.target.value)} name="email" type="email" placeholder="Email" name="email" required />
                                </div>

                                <div className="form_group">
                                    <label for="Password " >Password:</label>
                                    <input onInput={(e) => setPassword(e.target.value)} type="password" placeholder="Password" name="Password" required />
                                </div>
                                <div className="text-danger error__message text-center my-3" >{formStatus.slice(5, formStatus.length)}</div>
                                <div className="form-group text-center">
                                    <button class="submit__btn fw-3" onClick={logIn}  >{isLoading ? <img src={loader} width="10%" alt="loader" /> : 'Log In'}</button>
                                </div>
                            </div>

                            :
                            // signgup form

                            <div className=" login-form p-3 " >
                                <div className="form_group">
                                    <label for="email" >EMAIL:</label>
                                    <input onInput={(e) => setEmail(e.target.value)} name="email" type="email" placeholder="Email" name="email" required />
                                </div>

                                <div className="form_group">
                                    <label for="Password " >Password:</label>
                                    <input onInput={(e) => setPassword(e.target.value)} type="password" placeholder="Password" name="Password" required />
                                </div>
                                <div className="text-danger error__message text-center my-3" >{formStatus.slice(5, formStatus.length)}</div>
                                <div className="form-group text-center">
                                    <button class="submit__btn fw-3" onClick={signUp}  >{isLoading ? <img src={loader} width="10%" alt="loader" /> : 'Sign up'}</button>

                                </div>
                            </div>

                    }
                    <h5 className="toggleForm text-primary text-capitalize" onClick={() => setIsLoginForm(!isLoginForm)} >
                        {isLoginForm ? "Dont have an account? Sign Up " : "Already have an account? Log In "}
                    </h5>
                </div>
            </div>
        </>
    )
}

export default Login
