import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { FaTimesCircle,FaCheckCircle,FaPhoneAlt,FaInfoCircle } from 'react-icons/fa';
import {Link} from 'react-router-dom'
import '../Styles/table.scss'
import requests from '../Helpers/requests'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


function Abuses() {
    const [isLoading, setLoading] = useState(true)

const [abuses,setAbuses] = useState([]) 

useEffect(()=>{
    axios.get(`${requests.getAbuses}`).then((response)=>{
        setAbuses(response.data)
        setLoading(false)
    })
},[])
    return (
        <div className="container table-responsive my-3 position-relative" >
        {
        isLoading ?   
        <div className="loader">
             {/* <h5>Content Loading</h5> */}
        <Loader
        type="ThreeDots"
        color="#83418b"
        height={100}
        width={100}
        timeout={0} //0 secs
        />
        </div>
        :
        <>
                       <div className="d-flex justify-content-center align-items-center my-3">
                       <h3 className="text-center d-inline" >ABUSES </h3> <span class="badge badge-pill">{abuses.length}</span>
                       </div>

        
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Abuser id</th>
                                <th scope="col">note</th>
                                <th scope="col">acted On By</th>
                                <th scope="col">action Date</th>
                                <th scope="col"> create Date</th>
                                <th scope="col"> reporter Id</th>
                            </tr>
                        </thead>
                        <tbody>
                    
                            {
                                abuses.map((abuse,index) =>(
                                    <tr key={index}>
                                    <th scope="row">{abuse.id}</th>
                                    <td>{abuse.abuserId} </td>
                                    <td>{abuse.note} </td>
                                    <td>{abuse.actedOnBy} </td>
                                    <td>{abuse.actionDate} </td>
                                    <td>{abuse.createDate} </td>
                                    <td>{abuse.reporterId} </td>
        
                                    {/* <td><div className="table__button rounded-circle" ><Link to={`/member/${abuse.id}`}  className=" ">  <FaInfoCircle /></Link> </div></td> */}
        
                                    </tr>
                
                                ))
                            }
                           
                        </tbody>
                    </table>
                    </>
             }
        
                </div>
    )
}

export default Abuses
