import React from 'react'
import TableHeadings from './TableHeadings'

function MemberPayments({payments}) {
    return (
        <div className="col-12 ">

                  <div className="table-responsive" >
{
    payments.length > 0 ?

    <div className="overflow__scroll">
         <table className="table ">

<TableHeadings  headings={[
                    "id",
                    "amount",
                    "note",
                    "create date",
                    "ref id",
                    "sourceType",
                    "source Id",
                    "debit / Credit",
                    "member ID"
                    ]} />

                    <tbody>

                    {
                      payments.map((payment,index) =>(
                        <tr key={index}>
                        <th scope="row">{payment.id}  </th>
                        <td scope="row">#{new Intl.NumberFormat().format(payment.amount)}  </td>
                    <td scope="row">{payment.note}  </td>
                    <td scope="row">{payment.createDate.slice(0,10)}  </td>
                    <td scope="row">{payment.refId}  </td>
                    <td scope="row">{payment.sourceType}  </td>
                    <td scope="row">{payment.sourceId}  </td>
                    <td scope="row">{payment.debitCredit=="C" ? "Credit" : "Debit" } </td>


                           </tr>
                           // {console.log(skill.note)}
                        ))

                    }


</tbody>
                    
                      </table>
    </div>
                     

:<h5 className="text-danger"><u>NO DATA TO DISPLAY</u></h5>
}
                  </div>
              </div>
    )
}

export default MemberPayments
