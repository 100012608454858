import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom"
import '../Styles/verify.scss'
import { BsCheckCircleFill } from 'react-icons/bs';

function VerifyMemberSkill() {
const [isVerifySuccess,SetIsVerifySuccess] = useState(false)
  const { userId, skillId } = useParams()
  const [note, setNote] = useState("")

  const getNote = (e) => {
    setNote(e.target.value)
  }

  const getSkillId = (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("userid", userId);
    myHeaders.append("hasspass", "23495505togg");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "isVerify": true,
      "verifyBy": userId,
      "verifyNote": note
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://skillo.justwebtech.com/api/skill/${skillId}/verify`, requestOptions)
      .then(response => response.text())
      .then(result => SetIsVerifySuccess(true))
      .catch(error => console.log('error', error));

    // fetch(`https://skillo.justwebtech.com/api/skill/${skillId}/verify`, requestOptions)
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));

  }

  return (

    <div className="container p-3" >
      <section className="verify__container">
      {
        isVerifySuccess==false ? 

      
        <form className="verify__form  p-3">
          <textarea type="text" onInput={getNote} placeholder="VERIFY NOTE" rows="4" cols="50" ></textarea>
         <p className="text-center text-white">*Write a short note on this skill.</p>
<div className="text-center">
<button onClick={getSkillId} className="submit_btn px-5 py-1 ">Verify skill</button>

</div>
        </form>
      
      :
      <div className="verifySuccessful text-center py-5 px-5 rounded">
<div className="fs-1 mb-2"><BsCheckCircleFill /></div>
<h5>Skill Verified Successfully!</h5>

<Link to={`/member/${userId}`} >
<button className="btn btn-primary mt-3">Go Back</button>
</Link>

      </div>
    }
    </section>
     </div>
  )
}

export default VerifyMemberSkill
